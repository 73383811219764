import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-form"
export default class extends Controller {
  connect() {
    if(navigator.userAgent.match(/(iPhone|iPad|Android)/)){
    } else if(location.href.match(/\/\d+$/)) {
    } else {
      //$('#keyword').focus();
      document.getElementById('keyword').focus()
    }
  }

  submit() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, 200)
  }

  clear() {
    $('table.status input[type=checkbox]').prop("checked", false);
    this.submit();
  }
}
