import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="jpostal"
export default class extends Controller {
  static targets = [
    "postalCode",
    "address"
  ]

  // --------------------------------------------------------
  // SET ADDRESS
  // --------------------------------------------------------
  setAddress() {
    $(this.postalCodeTarget).jpostal({
      postcode : $(this.postalCodeTarget),
      address :  {
        ["#" + this.addressTarget.id] : '%3%4%5'
      }
    });
  }
}
