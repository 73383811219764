import { Controller } from "@hotwired/stimulus"

import * as AutoKana from "vanilla-autokana"

// Connects to data-controller="auto-kana"
export default class extends Controller {
  static targets = [
    "kanji",
    "kana"
  ]

  connect() {
    //true：カタカナ、false：ひらがな（デフォルト）
    // AutoKana.bind(this.kanjiTarget.id, this.kanaTarget.id, { katakana: false });
    AutoKana.bind(this.kanjiTarget.id, this.kanaTarget.id);
  }
}
