import { Controller } from "@hotwired/stimulus"
import { Dropzone } from "dropzone"

// Connects to data-controller="dropzone"
export default class extends Controller {
  connect() {
    let form = document.getElementById("dropzone"); // targetにかえる
    new Dropzone(form, {
      paramName: 'attachment[file]',
      dictDefaultMessage: 'ここにファイルをドラッグ＆ドロップしてください<br/>' +
                          '<p>または、クリックしてファイルを選択してください</p>' +
                          '<p>上限 : 10MB<br/>( ファイルは複数選択できます )</p>',
      init: function() {
        this.on('success', function(file, responseText) {
          // console.log(responseText);
          Turbo.renderStreamMessage(responseText);
        });
      }
    });
  }
}
