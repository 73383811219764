import { Controller } from "@hotwired/stimulus"

// jquery, jquery-ui, datepicker, daysjs はlayoutでCDNロード

// Connects to data-controller="datepicker"
export default class extends Controller {
  connect() {
    const d = new Date();
    const minDate = this.data.get("minDate") ? new Date(this.data.get("minDate")) : dayjs(d).add(-10, 'years').toDate();
    const maxDate = this.data.get("maxDate") ? new Date(this.data.get("maxDate")) : dayjs(d).add(5, 'years').toDate();
    $(this.element).datepicker({
      minDate: minDate,
      maxDate: maxDate,
    });
    this.element.readOnly = true;
    if (this.element.value) {
      this.element.value = dayjs(this.element.value).locale('ja').format('YYYY-MM-DD (dd)');
    }
  }

  disconnect() {
    if('datepicker' in $(this.element)) {
      $(this.element).datepicker("destroy");
    }
  }
}

$.datepicker.setDefaults({
  firstDay: 1,
  dateFormat: "yy-mm-dd (D)",
  changeYear: true,
  changeMonth: true,
  yearRange: '-100:+50',
  showOtherMonths: true,
  selectOtherMonths: true,
  showButtonPanel: true,
  beforeShow: showAdditionalButton,
  onChangeMonthYear: showAdditionalButton,
  onUpdateDatepicker: function() {
    //$('#ui-datepicker-div table td a').attr('href', 'javascript:;');
    $(".ui-datepicker a").removeAttr("href");
  },
  onSelect: function() {
    console.log(this.value);
    triggerEvent(this, 'input');
  }
});

$.datepicker._gotoToday = function(id) {
	var target = $(id);
	var inst = this._getInst(target[0]);
	var date = new Date();
	this._setDate(inst,date);
	this._hideDatepicker();
}

function showAdditionalButton(input) {
  setTimeout((function() {
    const buttonPanel = $(input).datepicker('widget').find('.ui-datepicker-buttonpane');
    const btn = $('<button class="ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all" type="button">クリア</button>');
    btn.unbind('click').bind('click', function() {
      $.datepicker._clearDate(input);
    });
    btn.appendTo(buttonPanel);
  }), 1);
};

function triggerEvent(element, event) {
  if (document.createEvent) {
    // IE以外
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent(event, true, true ); // event type, bubbling, cancelable
    return element.dispatchEvent(evt);
  } else {
    // IE
    var evt = document.createEventObject();
    return element.fireEvent("on"+event, evt)
  }
}

// --------------------------------------------------------
// BACK BUTTON HACK
// --------------------------------------------------------
// document.addEventListener("turbo:before-cache", function() {
//   $.datepicker.dpDiv.remove();
//   $("input.hasDatepicker").each(function(){
//     const elm = $(this);
//     elm.datepicker("destroy");
//   })
// });
//
// document.addEventListener("turbo:before-render", function(event) {
//   $.datepicker.dpDiv.appendTo(event.detail.newBody);
// });
