import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-kana"
export default class extends Controller {
  connect() {
    const el = $(this.element);
    el.select2({
      language: "ja",
      theme: "bootstrap-5",
      selectionCssClass: "select2--small",
      dropdownCssClass: "select2--small"
    });
    //// BACK BUTTON HACK
    // console.log($('span.select2-container').length);
    // if($('span.select2-container').length == 2){
    //   $('span.select2-container').last().remove();
    //   $('span.select2-container').first().css('width', '');
    // }
    if(el.nextAll('span.select2-container').length == 2){
      el.nextAll('span.select2-container').last().remove();
      el.nextAll('span.select2-container').first().css('width', '');
    }
  }
}
