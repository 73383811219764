// 利用側にて以下が必要
// %script{type: "application/json", "data-item-type-target": "optRp"}= Item.items_with_pa_type("010").to_json.html_safe
// %script{type: "application/json", "data-item-type-target": "optCp"}= Item.items_with_pa_type("020").to_json.html_safe
// %script{type: "application/json", "data-item-type-target": "optAd"}= Item.items_with_pa_type("030").to_json.html_safe
// pa_typeを使用しない場合には以下が必要
// = select_tag :dummy, options_for_select([['','']]), "data-item-type-target": "select2", class: "d-none"

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="coupled-select"
export default class extends Controller {
  static targets = [
    "optRp",
    "optCp",
    "optAd",
    "select1",
    "select2",
    "select3"
  ]
  // static values = {
  //   select2: { type: String, default: "" },
  //   blank: { type: Boolean, default: false },
  // }

  connect() {
    this._optRpData = JSON.parse(this.optRpTarget.textContent)
    this._optCpData = JSON.parse(this.optCpTarget.textContent)
    this._optAdData = JSON.parse(this.optAdTarget.textContent)
    // if(this.select1Target.value && this.select2Target.length <= 1) {
    //   setOptions(this.select2Target, this._data[this.select1Target.value], this.blankValue, this.select2Value);
    // }
    // console.log(this.selectedValue);
    // console.log(this._data);
    console.log(typeof(this.select2Target))
  }

  change() {
    // console.log("change called!!!!!")
    // console.log(this.select3Target.value)
    // console.log(this.select2Target.value)
    let opt
    switch (this.select1Target.value) {
      case "010":
        opt = this._optRpData
        break
      case "020":
        opt = this._optCpData
        break
      case "030":
        opt = this._optAdData
        break
    }

    // console.log(typeof select2)
    const pa = this.select2Target
    const reg = new RegExp(pa.options[pa.selectedIndex].text + ']')

    this.select3Target.length = 0
    this.select3Target.appendChild(new Option());
    for(const key in opt){
      if (opt[key].match(reg)) {
        this.select3Target.appendChild(new Option(opt[key], key))
      }
    }

    // console.log(this._data[this.select1Target.value]w);
    // setOptions(this.select2Target, this._data[this.select1Target.value], this.blankValue);

      // this.select2Target.length = 0;
      // for (const key in this._data[this.select1Target.value]) {
    //   console.log(`${key} : ${this._data[this.select1Target.value][key]}`);
    //   let option = document.createElement("option");
    //   option.setAttribute("value", key);
    //   option.innerHTML = this._data[this.select1Target.value][key];
    //   this.select2Target.appendChild(option);
    // }
  }
}

// function setOptions(elm, data, blank, selected = "") {
//   elm.length = 0;
//   if(blank) {
//     elm.appendChild(document.createElement("option"));
//   }
//   for (const key in data) {
//     // console.log(`${key} : ${data[key]}`);
//     let option = document.createElement("option");
//     option.setAttribute("value", key);
//     option.innerHTML = data[key];
//     if(key == selected) {
//       option.selected = true;
//     }
//     elm.appendChild(option);
//   }
// }
