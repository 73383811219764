import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="coupled-select"
export default class extends Controller {
  static targets = [
    "option",
    "select1",
    "select2"
  ]
  static values = {
    select2: { type: String, default: "" },
    blank: { type: Boolean, default: false },
  }

  connect() {
    this._data = JSON.parse(this.optionTarget.textContent)
    if(this.select1Target.value && this.select2Target.length <= 1) {
      setOptions(this.select2Target, this._data[this.select1Target.value], this.blankValue, this.select2Value);
    }
    // console.log(this.selectedValue);
    // console.log(this._data);
  }

  change() {
    // console.log(this._data[this.select1Target.value]w);
    setOptions(this.select2Target, this._data[this.select1Target.value], this.blankValue);

      // this.select2Target.length = 0;
      // for (const key in this._data[this.select1Target.value]) {
    //   console.log(`${key} : ${this._data[this.select1Target.value][key]}`);
    //   let option = document.createElement("option");
    //   option.setAttribute("value", key);
    //   option.innerHTML = this._data[this.select1Target.value][key];
    //   this.select2Target.appendChild(option);
    // }
  }
}

function setOptions(elm, data, blank, selected = "") {
  elm.length = 0;
  if(blank) {
    elm.appendChild(document.createElement("option"));
  }
  for (const key in data) {
    // console.log(`${key} : ${data[key]}`);
    let option = document.createElement("option");
    option.setAttribute("value", key);
    option.innerHTML = data[key];
    if(key == selected) {
      option.selected = true;
    }
    elm.appendChild(option);
  }
}
