import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    let opt = {
      currencySymbol: '',
      decimalPlaces: 0,
      unformatOnSubmit: true,
      modifyValueOnWheel: false,
      // emptyInputBehavior: 'null'
    }
    // console.log(this.data.get("type"))
    if(this.data.get("type") === "kg"){
      opt.suffixText = ' kg'
      opt.decimalPlaces = 1
    } else if(this.data.get("type") === "yen") {
      opt.currencySymbol = '¥ '
      opt.decimalPlaces = 0
    } else if(this.data.get("type") === "yen_f") {
      opt.currencySymbol = '¥ '
      opt.decimalPlaces = 1
    } else if(this.data.get("type") === "percentage") {
      opt.suffixText = ' %'
      opt.decimalPlaces = 2
    }
    new AutoNumeric(this.element, opt)
  }
}
