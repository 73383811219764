import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "receivable",
  ]

  // --------------------------------------------------------
  // CONNECT
  // --------------------------------------------------------
  connect() {
    // console.log(yen(10000));
    // newの時はrecalcする
  }

  // --------------------------------------------------------
  // CHANGE CASH AMOUNT
  // --------------------------------------------------------
  changeCashAmount() {
    // $('input#cash_amount').val(yen(cashAmount()));
    recalc();
  }
  // --------------------------------------------------------
  // CHANGE APPLY CHECK
  // --------------------------------------------------------
  changeApplyCheck() {
    if(!event.target.checked){
      // $(event.target).closest('tr').find('input.detail_paid_price').val(0).blur();
      AutoNumeric.getAutoNumericElement($(event.target).closest('tr').find('input.detail_paid_price').get(0)).set(0);
    }
    recalc();
  }
  // --------------------------------------------------------
  // 全解除
  // --------------------------------------------------------
  uncheckAll() {
    $('input.detail_paid_price').each(function(i, f) {
      $('input.applied').eq(i).prop('checked', false);
      // $(f).val(0).blur();
      AutoNumeric.getAutoNumericElement(f).set(0);
    });
    recalc();
  }
}

// --------------------------------------------------------
// RECALC
// --------------------------------------------------------
function recalc() {
  const receivable = removeYen($('#receivable').text()); // 未収金額合計
  let camount = cashAmount();                            // 消込原資
  $('input#cash_amount').val(yen(camount));
  cashApply();                                           // 消込
  const capplied = removeYen($('td#cash_applied').text());
  const shortage = receivable - capplied;                // 不足金額
  $('#payment_shortage').val(shortage); $('#p-shortage').text(yen(shortage));
  const excess = camount - capplied > 0 ? camount - capplied : 0; // 預り金
  $('#payment_excess').val(excess); $('#p-excess'). text(yen(excess));
}

// --------------------------------------------------------
// CASH AMOUNT 消込原資
// --------------------------------------------------------
function cashAmount() {
  const v1 = removeYen($('input#before_excess').val());
  const v2 = removeYen($('input#paid_price').val());
  const v3 = removeYen($('input#transfer_fee').val());
  const v4 = removeYen($('input#offset_price').val());
  const v5 = removeYen($('input#bad_debt').val());
  return v1 + v2 + v3 + v4 + v5;
}

// --------------------------------------------------------
// CASH APPLY 消込
// --------------------------------------------------------
function cashApply() {
  let camount = removeYen($('input#cash_amount').val());
  let capplied = 0
  $('input.detail_paid_price').each(function(i, f) {
    if($('input.applied').eq(i).prop('checked')) {
      const receivable = removeYen($(f).closest('tr').find('.receivable').text());
      const an = AutoNumeric.getAutoNumericElement(f);
      if(camount >= receivable) {
        // $(f).val(receivable).blur();
        // $(f).val(receivable);
        // $(f).trigger("change");
        an.set(receivable);
        capplied += receivable;
        camount -= receivable;
      } else {
        // $(f).val(camount).blur();
        // $(f).val(camount);
        // $(f).trigger("change");
        an.set(camount);
        capplied += camount
        camount = 0;
      }
    } else {
      const dpp = removeYen($(f).val());
      capplied += dpp;
      camount -= dpp;
    }
  });
  $('td#cash_applied').text(yen(capplied));
}

// -------------------------------------------------
// 数字→金額
// -------------------------------------------------
function yen(n) {
  return '¥ ' + Number(n).toLocaleString();
}

// -------------------------------------------------
// 金額→数字
// -------------------------------------------------
function removeYen(s) {
  console.log(s);
  return Number(s.replace('¥ ', '').replace(/,/g, ''));
}
