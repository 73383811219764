import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="leaflet"
export default class extends Controller {
  connect() {
    const latitude = this.data.get("latitude");
    const longitude = this.data.get("longitude");
    const map = L.map('map').setView([latitude, longitude], 13);
    const gsi = L.tileLayer('https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png', {
      attribution: "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
    });
    const osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    const gmap = L.tileLayer('https://mt1.google.com/vt/lyrs=m&language=ja&x={x}&y={y}&z={z}', {
      attribution: '&copy; Google'
    });
    gmap.addTo(map);
    // const marker = L.marker([latitude, longitude], { icon: L.spriteIcon('red') }).addTo(map); // sprite plugin が必要
    const marker = L.marker([latitude, longitude]).addTo(map);
  }
}
