import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// Connects to data-controller="sortable"
export default class extends Controller {
  connect() {
    Sortable.create(this.element, {
      animation: 150,
      handle: '.handle',
      onSort: async function(e) {
        await fetch("/admin/users/sort", {
          method: "POST",
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            'Content-Type': 'application/json'
          },
          credentials: 'same-origin',
          body: JSON.stringify({"seq" : [...e.target.querySelectorAll('tr')].map(elm => elm.id)})
        }).then(response => {
          if (!response.ok) throw new Error(response.statusText);
          return response.json();
        }).then(json => {
          console.log(json.message);
        }).catch(error => {
          alert(error);
        });
        location.reload();
      }
    });
  }
}
