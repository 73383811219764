$(document).on('turbo:load', function() {
  // console.log("scroll_to_top.js!");
  const topBtn = $('button#scroll-to-top');
  const offset = 200;
  // const speed = 600;
  const speed = 200;
  topBtn.on('click', () => {
    //window.scroll({top: 0, behavior: 'smooth'});
    $('html').animate({scrollTop: 0}, speed);
  });

  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > offset) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
});
