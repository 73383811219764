import { Controller } from "@hotwired/stimulus"

import { Modal } from "bootstrap"

// Connects to data-controller="modal"
export default class extends Controller {
  static values = { uf: Boolean }

  connect() {
    let elm = document.getElementsByClassName('modal-backdrop')
    if(elm.length > 0) {
      elm[0].remove()
    }
    this.modal = new Modal(this.element)
    this.modal.show()
    this.ufValue = false
    console.log(this.ufValue)
    this.element.addEventListener('hidden.bs.modal', function (event) {
      console.log('aaaa')
      location.reload() // TENTATIVE
      //console.log(this.ufValue)
      // console.log(event.target.dataset.modalUfValue)
      // //if(this.ufValue) {
      // if(event.target.dataset.modalUfValue == 'true') {
      //   console.log('MODAL CLOSED!!!')
      //   location.reload() // TENTATIVE
      // }
    })

  }

  close(event) {
    // console.log(event)
    console.log(this.ufValue)
    if (event.detail.success) {
      // this.ufValue = true
      // console.log('FLAG ON!!!')
      // this.modal.hide()
      // location.reload() // TENTATIVE
    }
  }
}
